<template>
  <div>
    <van-uploader :after-read="afterRead"><img src="@/assets/sfz.png" mode="widthFix"
        style="width: 250px; height: 150px" /></van-uploader>
    <van-form @submit="onSubmit" @failed="onFailed" style="width: 97%; margin: auto; border-radius: 25px">
      <van-cell-group>
        <van-field v-model="form.memberName" name="memberName" label="姓名" left-icon="user-o" placeholder="姓名"
          :rules="[{ required: true, message: '姓名不能为空' }]" />
        <van-field v-model="form.sex" name="sex" label="性别" left-icon="expand-o" placeholder="性别"
          :rules="[{ required: true, message: '性别不能为空' }]" />
        <van-field v-model="form.identityCard" name="identityCard" label="身份证号" left-icon="idcard" placeholder="身份证号"
          :rules="[{ required: true, message: '身份证号不能为空' }]" />
        <van-field readonly clickable name="value1" :value="value1" label="开户名称" left-icon="certificate"
          placeholder="开户名称" @click="showPicker1 = true" :rules="[{ required: true, message: '开户名称不能为空' }]" />
        <van-popup v-model="showPicker1" round position="bottom">
          <van-picker show-toolbar :default-index="0" value-key="dictLabel" :columns="lwKhBankList"
            @cancel="showPicker1 = false" @confirm="onConfirm1">
          </van-picker>
        </van-popup>
        <van-field v-model="form.bankAddress" name="bankAddress" label="开户银行" left-icon="wap-home-o" placeholder="开户银行"
          :rules="[{ required: true, message: '开户银行不能为空' }]" />
        <van-field v-model="form.bankCardNo" type="digit" name="bankCardNo" label="银行卡号" left-icon="ecard-pay"
          placeholder="银行卡号" :rules="[{ pattern, message: '请输入正确银行卡号' }]" />
        <van-field v-model="form.phone" type="tel" name="phone" label="本人电话" left-icon="phone-o" placeholder="本人电话"
          :rules="[
            { required: true, message: '本人电话不能为空' },
            {
              validator: (val) => {
                return val.length === 11;
              },
              message: '手机号格式不正确',
            },
          ]" />
        <van-field v-model="form.emergencyContact" name="emergencyContact" label="紧急联系人" left-icon="friends-o"
          placeholder="紧急联系人" :rules="[{ required: true, message: '紧急联系人不能为空' }]" />
        <van-field v-model="form.emergencyRelation" name="emergencyRelation" label="联系人关系" left-icon="like-o"
          placeholder="联系人关系" :rules="[{ required: true, message: '紧急联系人关系不能为空' }]" />
        <van-field v-model="form.emergencyPhone" type="tel" name="emergencyPhone" label="联系人电话" left-icon="phone-circle-o"
          placeholder="联系人电话" :rules="[
            { required: true, message: '联系人电话不能为空' },
            {
              validator: (val) => {
                return val.length === 11;
              },
              message: '手机号格式不正确',
            },
          ]" />
        <van-field readonly clickable name="value" :value="value" label="所属工种" left-icon="medal-o" placeholder="工种"
          @click="showPicker = true" :rules="[{ required: true, message: '工种不能为空' }]" />
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker show-toolbar :default-index="0" value-key="dictLabel" :columns="columnsTypeWork"
            @cancel="showPicker = false" @confirm="onConfirm">
          </van-picker>
        </van-popup>
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Notify } from "vant";
import { ocrUserInfo, addLwMember, getDicts, qrCodeCheck } from "@/api/api";

export default {
  components: {
    [Notify.Component.name]: Notify.Component,
  },
  data () {
    return {
      imgFile: "",
      value: "",
      value1: "",
      form: { memberName: "", sex: "", identityCard: "" },
      columnsTypeWork: [],
      lwKhBankList: [],
      showPicker: false,
      showPicker1: false,
      gzDictCodeStr: null,
      bankNameCodeStr: null,
      pattern: /^([1-9]{1})(\d{14}|\d{18})$/,
    };
  },
  created () {
    let jsonStr = this.$route.query;
    this.form.projectId = jsonStr.projectId;
    this.form.contractId = jsonStr.contractId;
    this.getQrCodeCheck()
  },
  methods: {
    getQrCodeCheck () {
      qrCodeCheck(this.form).then(res => {
        console.log(res.data)
        if (res.data == null || res.data.code != 200) {
          this.$router.push("/qrCodeOver");
        } else {
          this.getDictGz();
        }
      })
    },
    getDictGz () {
      getDicts("type_of_work_wage").then((response) => {
        this.columnsTypeWork = response.data.data;
      });
      getDicts("lw_kh_bank").then((response) => {
        this.lwKhBankList = response.data.data;
      });
    },
    onConfirm (value) {
      this.value = value.dictLabel;
      this.showPicker = false;
      this.gzDictCodeStr = value.dictValue;
    },
    onConfirm1 (value) {
      this.value1 = value.dictLabel;
      this.showPicker1 = false;
      this.bankNameCodeStr = value.dictValue;
    },
    afterRead (file) {
      // 此时可以自行将文件上传至服务器
      let data = { imgFile: file.content };
      ocrUserInfo(data).then((response) => {
        if (response.data.code != 200) {
          Notify({ type: "warning", message: response.data.msg });
        } else {
          var userInfo = JSON.parse(response.data.msg);
          console.log(userInfo["name"]);
          console.log(userInfo["sex"]);
          console.log(userInfo["num"]);
          this.form.memberName = userInfo["name"];
          this.form.sex = userInfo["sex"];
          this.form.identityCard = userInfo["num"];
        }
      });
    },
    onSubmit (values) {
      values.projectId = this.form.projectId;
      values.contractId = this.form.contractId;
      values.gzDictCode = this.gzDictCodeStr;
      values.bankName = this.bankNameCodeStr;
      addLwMember(values).then((response) => {
        if (response.data.code != 200) {
          Notify({ type: "warning", message: response.data.msg });
        } else {
          Notify({ type: "success", message: "实名认证成功" });
          this.$router.push("/success");
        }
      });
    },
    onFailed (errorInfo) {
      // 本方法会在表单提交验证失败时运行,也就是用户名和密码有null时运行
      // 参数errorInfo是错误信息,要想知道其中内容,直接输出即可
      console.log(errorInfo);
    },
  },
};
</script>
