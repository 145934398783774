import axios from 'axios';

const API_URL = 'https://ts.cciictop.com/prod-api/';
// const API_URL = 'http://localhost:8091/';

export function ocrUserInfo (data) {
  return axios.post(`${API_URL}pdf/ocrUserInfo`, data);
}

export function addLwMember (data) {
  return axios.post(`${API_URL}pdf/lwAddMember`, data);
}


export function qrCodeCheck (data) {
  return axios.post(`${API_URL}pdf/qrCodeCheck`, data);
}

export function getDicts (dictType) {
  return axios.get(`${API_URL}pdf/dictType/` + dictType);
}

